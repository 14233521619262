import { Button, Layout, Spin, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { useForm } from 'antd/es/form/Form'
import { get, put } from '../../Common/api'
import { t } from 'i18next'
import OwnerForm from '../OwnersPage/OwnerForm'

export default function OwnerPage () {
  const { id } = useParams()
  const [form] = useForm()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getOwner()
  }, [])

  const getOwner = async () => {
    setLoading(true)
    await get(`admin/owners/${id}`, {}, (json) => {
      setLoading(false)
      form.setFieldsValue(json)
    }, () => {
      setLoading(false)
    })
  }

  const onFinish = (values) => {
    saveOwner(values)
  }

  const saveOwner = (params) => {
    setLoading(true)
    put(`admin/owners/${id}`, params, () => {
      setLoading(false)
      notification.success({ message: t('Admin.message.updateSuccess'), placement: 'bottom' })
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  return (
    <Layout style={{ padding: '1em', backgroundColor: 'white', marginTop: '2em' }}>
      <Spin spinning={loading}>
          <OwnerForm form={form} edit={true} onFinish={onFinish} />
          <Button type="primary" htmlType="submit" onClick={form.submit}>
            {t('Admin.btn.submit')}
          </Button>
      </Spin>
    </Layout>
  )
}
