import { Layout, Menu } from 'antd'
import { t } from 'i18next'
import React, { useContext, useState } from 'react'
import { Link, Outlet, useParams } from 'react-router-dom'
import { Color } from '../../Common/Color'
import BoxContext from './BoxContext'

const { Header, Content } = Layout

const items = (id) => [
  {
    key: `/admin/box/${id}/calendar`, label: (<Link to={`/admin/box/${id}/calendar`}>{t('Admin.Box.menu.calendar')}</Link>)
  },
  {
    key: `/admin/box/${id}/info`, label: (<Link to={`/admin/box/${id}/info`}>{t('Admin.Box.menu.info')}</Link>)
  },
  {
    key: `/admin/box/${id}/opening`, label: (<Link to={`/admin/box/${id}/opening`}>{t('Admin.Box.menu.opening')}</Link>)
  },
  {
    key: `/admin/box/${id}/logs`, label: (<Link to={`/admin/box/${id}/logs`}>{t('Admin.Box.menu.logs')}</Link>)
  }
]

export default function BoxMenuLayout ({ children }) {
  const { id } = useParams()
  const { box } = useContext(BoxContext)
  const [current, setCurrent] = useState(location.pathname)

  return (
    <>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        {box?.name}
      </Layout>
      <Header style={{ padding: 0, backgroundColor: Color.white, color: Color.dark_blue }}>
        <Menu style={{ float: 'left', backgroundColor: Color.white, color: Color.dark_blue, width: '100%' }}
          onClick={(e) => setCurrent(e.key)}
          selectedKeys={[current]}
          mode='horizontal'
          items={items(id)} />
      </Header>
      <Content>
        {children || <Outlet />}
      </Content>
    </>
  )
}
