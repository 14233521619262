import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'

const columns = () => [
  {
    width: 70,
    title: t('Admin.AlertList.table.created_at'),
    dataIndex: 'created_at',
    key: 'created_at',
    render: (_, o) => `${dayjs(o?.created_at).format('DD/MM/YYYY HH:mm')}`
  },
  {
    width: 150,
    title: t('Admin.AlertList.table.message'),
    dataIndex: 'message',
    key: 'message'
  },
  {
    width: 50,
    title: t('Admin.AlertList.table.level'),
    dataIndex: 'level',
    key: 'level'
  },
  {
    width: 100,
    title: t('Admin.AlertList.table.box'),
    key: 'box',
    render: (_, o) => <Link to={`/admin/box/${o?.box?.id}/calendar`}>{o?.box?.name}</Link>
  },
  {
    width: 100,
    title: t('Admin.AlertList.table.slot'),
    key: 'slot',
    render: (_, o) => o.slot && <Link to={`/admin/slots/${o?.slot?.id}`}>{o?.slot?.first_name} {o?.slot?.last_name}</Link>
  }
]

export default function AlertList ({ alerts }) {
  return (
    <CustTable

      height={'calc(100vh - 230px)'}
      initColumns={columns()}
      dataSource={alerts}
    />
  )
}
