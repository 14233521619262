import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { get } from '../../Common/api'
import ConfirmedSlot from './ConfirmedSlot'

import SlotLayout from './SlotLayout'

export default function SlotPage () {
  const { id } = useParams()
  const { t } = useTranslation()
  const [slot, setSlot] = useState(null)

  useEffect(() => {
    get(`public/slots/${id}`, {}, (json) => {
      setSlot(json)
    })
  }, [id])

  const date = slot?.begin_date
  const box = slot?.box
  const title = slot?.is_cancel ? 'CancelSlotPage' : 'ConfirmedSlotPage'
  return (
        <SlotLayout date={date} box={box} title={t(`Public.${title}.title`)} disabled={true} slot={slot}>
         <ConfirmedSlot slot={slot} setSlot={setSlot} />
        </SlotLayout>
  )
}
