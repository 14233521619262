import { notification, Spin } from 'antd'
import { t } from 'i18next'
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useLocalStorage } from 'react-use'
import { post } from '../Common/api'
import { debounce } from '../Common/debounce'
import eventBus from '../Common/EventBus'

const GlobalContext = createContext()
export default GlobalContext

export const GlobalContextProvider = ({ children }) => {
  const [data, setData] = useLocalStorage('data', { isAuth: false })
  const [loading, setLoading] = useState(false)
  const [eventOn, setEventOn] = useState(false)

  const navigate = useNavigate()

  const onNotAuthenticated = useCallback(debounce(() => {
    navigate('/login')
    logout()
  }, 100), [])

  useEffect(() => {
    if (eventOn) { return }
    setEventOn(true)
    eventBus.on('NOT_AUTHENTICATED', onNotAuthenticated)
    return () => {
      eventBus.remove('NOT_AUTHENTICATED', onNotAuthenticated)
    }
  }, [])

  const login = async (params) => {
    setLoading(true)
    await post('admin/login', params, (response) => {
      setData({ isAuth: true, user: response.user })
      notification.success({ message: response.message, placement: 'bottom' })
      navigate('/admin')
    }, (response) => {
      notification.error({ message: response.error, placement: 'bottom' })
    })
    setLoading(false)
  }

  const logout = () => {
    setData({ isAuth: false })
  }

  const updatePassword = async (params) => {
    setLoading(true)
    await post('admin/users/update_password', params, (response) => {
      notification.success({ message: response.message, placement: 'bottom' })
      navigate('/admin/login')
    }, (response) => {
      notification.error({ message: errorMessages(response.errors, 'Admin.UpdatePassword'), placement: 'bottom' })
    })
    setLoading(false)
  }

  const checkToken = async (params) => {
    setLoading(true)
    await post('admin/users/update_password', params, () => {
    }, (response) => {
      if (Object.keys(response.errors).includes('reset_password_token')) {
        notification.error({ message: 'Lien expiré', placement: 'bottom' })
        navigate('/admin/login')
      }
    })
    setLoading(false)
  }

  const errorMessages = (errors, traductionPath) => {
    return Object.keys(errors).map(key => (
      <>{t(traductionPath + '.' + key) + ': ' + errors[key]}</>
    ))
  }

  const values = {
    setData,
    data,
    login,
    logout,
    updatePassword,
    checkToken,
    errorMessages
  }
  return <GlobalContext.Provider value={values}>
    <Spin spinning={loading}>
      {children || <Outlet />}
    </Spin>
  </GlobalContext.Provider>
}
