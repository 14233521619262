import React, { createContext } from 'react'
import { Outlet } from 'react-router'
import { useLocalStorage } from 'react-use'

const SlotContext = createContext()
export default SlotContext

export const SlotContextProvider = () => {
  const [data, setData] = useLocalStorage('slot')

  const values = {
    data,
    setData
  }

  return <SlotContext.Provider value={values}>
    <Outlet />
  </SlotContext.Provider>
}
