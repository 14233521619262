import { Card, Typography } from 'antd'
import { t } from 'i18next'
import React, { useContext } from 'react'
import { Color } from '../../Common/Color'
import SpecialistContext from './SpecialistContext'

export default function SpecialistSlots () {
  const { slots, day, saveSlot, specialist, reason } = useContext(SpecialistContext)

  const onClick = (slot, calendarId, reasonId) => {
    saveSlot(slot, calendarId, reasonId)
  }

  const displaySlots = (slots) => {
    if (slots && slots.length > 0) {
      return slots.map((slot) => (
        <div className='hoverable'
          onClick={() => onClick(slot, specialist?.id, reason.id)}
          key={slot.time.format('DD/MM/YYYY HH:mm')}
          style={{
            textAlign: 'center',
            width: '75px',
            display: 'inline-block',
            fontSize: '16px',
            border: '1px solid black',
            borderRadius: '2em',
            padding: '0.4em 1em',
            margin: '1em 1em',
            '--hover-color': Color.red,
            '--hover-border-color': Color.red,
            cursor: 'pointer'
          }}
        >
          {slot.time.format('HH:mm')}
        </div>
      ))
    } else {
      return t('Public.Specialist.noSlots')
    }
  }

  return (
    <>
      <Typography.Title level={2} style={{ marginBottom: '1em', textAlign: 'center' }}>{t('Public.Specialist.slotsAvailable')}</Typography.Title>
      <Card title={<Typography.Title level={4} style={{ color: Color.white }}>{day?.format('D MMMM YYYY')}</Typography.Title>}
        bordered={false}
        bodyStyle={{ width: '30em', textAlign: 'center', height: '28.5em', overflowY: 'scroll' }}
        headStyle={{ textAlign: 'center', backgroundColor: Color.green }}
        style={{ width: '30.5em', boxShadow: '1px 1px 10px #4f5d75' }}
      >
        { displaySlots(slots) }
      </Card>
    </>
  )
}
