import React from 'react'

import { Color } from '../../Common/Color'
import BoxCard from '../BoxPage/BoxCard'
import PublicHeader from '../PublicHeader'
import SlotCard from './SlotCard'
import UserCard from '../User/UserCard'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

export default function SlotLayout ({ box, isNow, date, disabled = false, title, children, slot }) {
  const navigate = useNavigate()

  const buttonStyle = (specialist) => {
    if ((location.href.includes('specialist') && specialist) || (!location.href.includes('specialist') && !specialist)) {
      return { backgroundColor: Color.red, color: Color.white }
    } else {
      return {}
    }
  }

  return (
        <div style={{ gap: '1em', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: Color.white }}>
            <PublicHeader />
            <div className='LayoutSentence' style={{ fontSize: '20px', marginBottom: '10px', fontWeight: 'bold' }}>
                {title}
            </div>
            <div
                className='slotPageContainer'
                style={{
                  gap: '1em',
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'horizontal',
                  position: 'relative'
                }}>
                <div className="cards" style={{ gap: '1em', display: 'flex', flexDirection: 'column' }}>
                  {
                    !slot && <>
                      <Button onClick={() => { navigate(`/box/${box.id}`) }} style={buttonStyle(false)}>Médecins généralistes</Button>
                      <Button onClick={() => { navigate(`/box/${box.id}/specialist`) }} style={buttonStyle(true)}>Médecins spécialistes</Button>
                    </>
                  }
                  {box && <BoxCard box={box} disabled={disabled} />}
                  {date && <SlotCard date={date} isNow={isNow} box={box} disabled={disabled} />}
                  {slot && <UserCard slot={slot} />}
                </div>

                {children}
            </div>
        </div>
  )
}
