import { Table } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { useKeyPressEvent } from 'react-use'
import { VList } from 'virtuallist-antd'

export default function CustTable (
  {
    dataSource, initColumns, height, rowKey, rowSelection, onRow,
    bordered, className, coloredRows, vid, initSelectedRowId, expandable,
    style,
    showHeader = true,
    extraOnRow = () => { },
    rowClassName = () => { },
    updateContextOnSelect = (_) => { }
  }
) {
  rowKey = rowKey || ((record) => record?.id)

  const [selectedRow, setSelectedRow] = useState(
    dataSource?.find((e) => {
      return rowKey(e) === initSelectedRowId
    })
  )

  useEffect(() => {
    setColumns(initColumns.map((col) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width
      })
    })))
  }, [initColumns])

  const [columns, setColumns] = useState(
    initColumns.map((col) => ({
      ...col,
      onHeaderCell: (column) => ({
        width: column.width
      })
    }))
  )

  const vc = useMemo(() => {
    return {
      ...VList({
        height,
        vid,
        resetTopWhenDataChange: false
      })
    }
  }, [vid, height])

  const rowClasses = (record, index) => {
    const classes = [rowClassName(record, index)]
    if (coloredRows) {
      classes.push((index % 2 === 0 ? 'table-row-light ' : 'table-row-dark '))
    }
    if (rowKey(record) === rowKey(selectedRow)) { classes.push('table-row-selected') }
    return classes.join(' ')
  }

  const onRowDefault = (record) => ({
    onClick: () => {
      if (record === selectedRow) { return }
      setSelectedRow(record)
      updateContextOnSelect(record)
      extraOnRow(record)
    }
  })

  const keyUp = () => {
    if (!selectedRow) { return }
    const record = dataSource[
      Math.max(0, dataSource.findIndex(e => e === selectedRow) - 1)]
    setSelectedRow(record)
    updateContextOnSelect(record)
  }

  const keyDown = () => {
    if (!selectedRow) { return }
    const record = dataSource[
      Math.min(dataSource.length - 1, dataSource.findIndex(e => e === selectedRow) + 1)
    ]
    setSelectedRow(record)
    updateContextOnSelect(record)
  }

  useKeyPressEvent('ArrowUp', keyUp)
  useKeyPressEvent('ArrowDown', keyDown)

  return (
    <Table
      style={style}
      rowClassName={rowClasses}
      columns={columns}
      dataSource={dataSource}
      rowKey={rowKey}
      pagination={false}
      scroll={{ y: height, x: '100%' }}
      size='small'
      rowSelection={rowSelection || null}
      bordered={bordered || true}
      className={className || ''}
      onRow={onRow || onRowDefault}
      showHeader={showHeader}
      expandable={expandable}
      components={vc}
    />
  )
}
