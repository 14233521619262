import { Collapse, Tag } from 'antd'
import CollapsePanel from 'antd/es/collapse/CollapsePanel'
import React, { useContext, useEffect, useState } from 'react'
import { get } from '../../Common/api'
import GlobalContext from '../GlobalContext'

export default function Changelog () {
  const { setData, data } = useContext(GlobalContext)
  const [markdownText, setMarkdownText] = useState()
  const [versions, setVersions] = useState()

  useEffect(() => {
    get('admin/changelogs', {}, (json) => {
      setMarkdownText(json.changelog)
      setVersions(json.versions)
      setData({ ...data, user: { ...data.user, changelog: true } })
    })
  }, [])

  const nextVersion = (line) => (
    versions[versions.indexOf(line) + 1] ? versions[versions.indexOf(line) + 1] : markdownText.length
  )

  const panels = () => (
    versions.map((line) => {
      if (line === 0) {
        return <CollapsePanel header={<>{cleanText(markdownText[line]) }<Tag color="green">Nouveau</Tag></>} key={line}>
          {renderContent(markdownText.slice(line + 1, nextVersion(line)), line)}
        </CollapsePanel>
      } else {
        return <CollapsePanel header={cleanText(markdownText[line])} key={line}>
          {renderContent(markdownText.slice(line + 1, nextVersion(line)), line)}
        </CollapsePanel>
      }
    })
  )

  const renderContent = (lines, number) => (
    lines.map((line) => (<p key={`${number}${lines.indexOf(line)}`} style={{ display: 'flex' }}>{cleanText(line)}</p>))
  )

  const cleanText = (line) => {
    if (line[0] === '#') {
      const lines = line.split('# ')
      return <b>{lines[lines.length - 1]}</b>
    } else if (line[0] === '+') {
      const lines = line.replace('+', 'AJOUT|').split('|')
      return <><div style={{ display: 'flex', fontWeight: 'bold', marginRight: '1em' }}>[<div style={{ color: 'green' }}>{lines[0]}</div>]</div> {lines[1]}</>
    } else if (line[0] === '-') {
      const lines = line.replace('-', 'SUPPRESSION|').split('|')
      return <><div style={{ display: 'flex', fontWeight: 'bold', marginRight: '1em' }}>[<div style={{ color: 'red' }}>{lines[0]}</div>]</div> {lines[1]}</>
    } else if (line[0] === '~') {
      const lines = line.replace('~', 'MODIFICATION|').split('|')
      return <><div style={{ display: 'flex', fontWeight: 'bold', marginRight: '1em' }}>[<div style={{ color: 'orange' }}>{lines[0]}</div>]</div> {lines[1]}</>
    } else if (line[0] === '=') {
      const lines = line.replace('=', 'CORRECTION|').split('|')
      return <><div style={{ display: 'flex', fontWeight: 'bold', marginRight: '1em' }}>[<div style={{ color: 'blue' }}>{lines[0]}</div>]</div> {lines[1]}</>
    } else if (line[0] === '!') {
      const lines = line.replace('!', 'A FAIRE|').split('|')
      return <><div style={{ display: 'flex', fontWeight: 'bold', marginRight: '1em' }}>[<div style={{ color: '#DB6A8F' }}>{lines[0]}</div>]</div> {lines[1]}</>
    } else { return line }
  }

  return (
    <div style={{ backgroundColor: 'white', padding: '2em', marginTop: '2em' }}>
      { versions && <Collapse>{panels()}</Collapse> }
    </div>
  )
}
