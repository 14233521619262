import React, { useContext } from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import GlobalContext from './GlobalContext'

export default function EnsureLogin ({ children }) {
  const globalContext = useContext(GlobalContext)

  if (!globalContext.data) {
    return <></>
  }
  if (globalContext.data.isAuth) {
    return children || <Outlet />
  } else {
    return (
      <Navigate to='/admin/login' replace={true} />
    )
  }
}
