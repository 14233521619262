import React, { useEffect, useState } from 'react'
import BoxCard from './BoxCard'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'

export default function BoxMap ({ boxes, lat, long, currentBox, setCurrentBox }) {
  const [map, setMap] = useState(null)

  const onBoxSelected = (box) => {
    if (map) {
      map.flyTo([box.lat, box.long], 13)
    }
  }

  useEffect(() => {
    if (currentBox) {
      onBoxSelected(currentBox)
    }
  }, [currentBox])

  useEffect(() => {
    if (map && boxes) {
      if (boxes[0].distance < 5) {
        map.fitBounds([[lat, long], ...boxes.filter((e) => e.distance < 5).map((box) => [box.lat, box.long])])
        map.setZoom(14)
      } else if (boxes[0].distance < 50) {
        map.fitBounds([[lat, long], ...boxes.filter((e) => e.distance < 50).map((box) => [box.lat, box.long])])
      } else {
        map.fitBounds(boxes.map((box) => [box.lat, box.long]))
      }
    }
  }, [map])

  if (!boxes || boxes.length === 0) {
    return (<></>)
  }

  const onMarkerClick = (box) => {
    setCurrentBox(box)
  }

  const center = [lat, long]

  const markers = boxes.map((box) => (
        <Marker key={box.id} position={[box.lat, box.long]} eventHandlers={{ click: () => onMarkerClick(box) }} >
      <Popup className='map-popup' maxWidth={350}>
                <BoxCard box={box} type='map' />
            </Popup>
        </Marker>
  ))

  return (
        <MapContainer
            ref={setMap}
            style={{ borderRadius: '2em', flex: 1, position: 'relative' }} center={center} zoom={13} scrollWheelZoom={true}>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers}
        </MapContainer>
  )
}
