import { Spin } from 'antd'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { Outlet, useNavigate, useParams } from 'react-router'
import { post, get } from '../../Common/api'
import { onlyUnique } from '../../Common/Common'
import dayjs from 'dayjs'
import SlotContext from '../Slot/SlotContext'
import { t } from 'i18next'

const SpecialistContext = createContext()
export default SpecialistContext

export const SpecialistContextProvider = () => {
  const { setData } = useContext(SlotContext)
  const [loading, setLoading] = useState(false)
  const [reasons, setReasons] = useState()
  const [reason, setReason] = useState()
  const [allSpecialists, setAllSpecialists] = useState()
  const [specialists, setSpecialists] = useState()
  const [specialist, setSpecialist] = useState()
  const [allMonthAvailabilities, setAllMonthAvailabilities] = useState()
  const [monthAvailabilities, setMonthAvailabilities] = useState()
  const [date, setDate] = useState(dayjs())
  const [day, setDay] = useState(dayjs())
  const [slots, setSlots] = useState()
  const { id } = useParams()
  const [box, setBox] = useState(null)
  const navigate = useNavigate()

  const getMonthAvailabilities = (monthDate, calendars) => {
    post('calendoc/month_availabilities',
      { date: monthDate.startOf('month').format('YYYY-MM-DD'), specialist_ids: calendars?.map(e => e.id) },
      (res) => {
        setAllMonthAvailabilities({ ...res.availabilities, date: monthDate.startOf('month') })
      })
  }

  const getDayAvailabilities = (monthDate, reasonId, specialists, box) => {
    post('calendoc/day_availabilities',
      { date: monthDate.format('YYYY-MM-DD'), reason_id: reasonId, specialist_ids: specialists.map((v) => (v.id)), box_id: box.id },
      (res) => {
        if (res.slots.length > 0) {
          setSlots(res.slots.map((v) => ({ ...v, time: dayjs(v.time) })).sort((a, b) => a.time - b.time))
        } else {
          setSlots(res.slots)
        }
      })
  }

  const defineCalendar = (specialists, allMonthAvailabilities) => {
    if (specialists.length === 0) {
      setMonthAvailabilities([])
    } else if (specialists.length > 1) {
      const colors = {}
      const result = []
      specialists.forEach(specialist => {
        allMonthAvailabilities[specialist.id].forEach(availability => {
          if (colors[availability.day] === undefined) {
            colors[availability.day] = [availability.color]
          } else {
            colors[availability.day].push(availability.color)
          }
        })
      })
      Object.keys(colors).forEach(day => {
        const color = colors[day].filter(onlyUnique).length > 1 ? '#01DF01' : colors[day].filter(onlyUnique)[0]
        result.push({ color, day })
      })
      setMonthAvailabilities(result)
    } else {
      setMonthAvailabilities(allMonthAvailabilities[specialists[0].id])
    }
    setDate(allMonthAvailabilities.date)
  }

  const saveSlot = (slot, calendarId, reasonId) => {
    const values = { specialist: true, slotTime: slot, calendarId, boxId: id, reasonId }
    setData(values)
    navigate(`/box/${box.id}/specialist_slot_create`)
  }

  useEffect(() => {
    setLoading(true)
    get(`public/boxes/${id}`, {}, (json) => {
      setBox(json)
      post('calendoc/specialist_info', { box_id: id }, (res) => {
        setAllSpecialists(res.specialists)
        setReasons(res.reasons)
        getMonthAvailabilities(date, res.specialists)
        if (res.reasons.length > 0) {
          setReason(res.reasons[0])
          getDayAvailabilities(date, res.reasons[0].id, res.specialists, json)
          setLoading(false)
        }
      })
    },
    () => { setLoading(false) })
  }, [id])

  useEffect(() => {
    if (allMonthAvailabilities && specialists) {
      defineCalendar(specialists, allMonthAvailabilities)
    }
  }, [specialists, allMonthAvailabilities])

  useEffect(() => {
    if (allSpecialists) {
      const filteredSpecialists = allSpecialists.filter(e => e.reasons.map(e => e.id).includes(reason.id))
      setSpecialists(filteredSpecialists)
      if (reason) {
        getDayAvailabilities(day, reason.id, filteredSpecialists, box)
      }
    }
  }, [specialist, day, reason, allSpecialists, box])

  const values = {
    getMonthAvailabilities,
    getDayAvailabilities,
    defineCalendar,
    reasons,
    reason,
    setReason,
    allSpecialists,
    specialists,
    specialist,
    setSpecialist,
    allMonthAvailabilities,
    monthAvailabilities,
    date,
    day,
    setDay,
    slots,
    box,
    saveSlot
  }

  return <SpecialistContext.Provider value={values}>
    <Spin spinning={loading} tip={t('Public.Specialist.loading')}> <Outlet /> </Spin>
  </SpecialistContext.Provider>
}
