import React from 'react'
import { UserOutlined } from '@ant-design/icons'
import PhoneDisplay from '../../Common/PhoneDisplay'

export default function UserCard ({ slot }) {
  const style = styleDefault()

  return (
        <div style={style.box} className="boxPageCard">
            <div className="icon" style={{ textAlign: 'center', display: 'flex' }}>
                <UserOutlined width="100px" style={style.icon} />
            </div>
            <div style={style.content} className="content">
                <div className="text" >
                    <b>{slot.last_name}</b> <br />
                    {slot.first_name} <br />
                    <PhoneDisplay value={slot.phone}/>
                    {slot.email}
                </div>
            </div>
        </div>
  )
}

const styleDefault = () => ({
  box: {
    boxShadow: '0px 0px 2px 2px #f2f2f2',
    borderRadius: '1em',
    padding: '1em',
    display: 'flex',
    gap: '1em',
    width: '350px',
    margin: '1px'
  },
  icon: {
    display: 'block',
    width: '100px',
    fontSize: '24px',
    alignSelf: 'center'
  },
  content: {
    alignSelf: 'center'
  }
})
