export const debounce = (fn, delay) => {
  // https://gist.github.com/simonw/c29de00c20fde731243cbac8568a3d7f
  let timer = null
  return (...args) => {
    const context = this
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      fn.apply(context, args)
    }, delay)
  }
}
