import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { EnvironmentFilled } from '@ant-design/icons'
import { Color } from '../../Common/Color'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import { capitalize } from 'lodash'
import BoxSchedules from '../BoxPage/BoxSchedules'

export default function BoxCard ({ box, type, currentBox, setCurrentBox }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isActive = currentBox && currentBox.id === box.id

  const style = type === 'map' ? styleMap : styleDefault(isActive)

  const dateToText = () => {
    if (!box.next_available_slot) { return null }
    if (box['available_now?']) {
      return t('Public.BoxList.now')
    } else if (dayjs(box.next_available_slot).isSame(dayjs(), 'day')) {
      return t('Public.BoxList.today') + dayjs(box.next_available_slot).format(' [à] HH[h]mm')
    } else {
      return dayjs(box.next_available_slot).format('ddd D MMMM [à] HH[h]mm')
    }
  }

  const onClick = () => {
    if (setCurrentBox) {
      setCurrentBox(box)
    }
  }

  const navigateToBox = (e) => {
    e.stopPropagation()
    navigate(`/box/${box.id}`)
  }

  const navigateToSlot = (e) => {
    e.stopPropagation()
    if (box['available_now?']) {
      navigate(`/box/${box.id}/slot_create/now`)
    } else {
      navigate(`/box/${box.id}/slot_create?date=${dayjs(box.next_available_slot).toISOString()}`)
    }
  }

  return (
    <div onClick={onClick} style={style.box}>
      <div>
        <img src={box.picture_url.url} style={style.image} />
      </div>
      <div style={style.content}>
        <div style={{ marginBottom: '1em' }}>
          <b>{box.name}</b> <br />
          <b><EnvironmentFilled />{box.distance} km</b> <br />
          {box.address} <br />
          {box.zip_code} {box.city}<br />
        </div>
        {box.next_available_slot &&
          <Button onClick={navigateToSlot} type="primary">{capitalize(dateToText())}</Button>
        }
        <a onClick={navigateToBox} style={{ color: Color.red, textDecoration: 'underline', marginTop: '1em', display: 'block' }}>
          {t('Public.BoxList.allSlots')}
        </a>
      </div>
      <BoxSchedules />
    </div>
  )
}

const styleMap = {
  box: {
    display: 'flex',
    flexDirection: 'column'
  },
  image: {
    width: '100%'
  },
  content: {
    padding: '10px',
    fontSize: '1.2em'
  }
}

const styleDefault = (isActive) => ({
  box: {
    boxShadow: `0px 0px 2px 2px ${isActive ? Color.red : '#f2f2f2'}`,
    borderRadius: '1em',
    padding: '1em',
    display: 'flex',
    gap: '1em'
  },
  image: {
    borderRadius: '1em',
    maxWidth: '200px'
  },
  content: {
    fontSize: '1.2em',
    maxWidth: '10em'
  }
})
