import React from 'react'
import { t } from 'i18next'
import { Input, Select } from 'antd'

const LEVELS = ['operation', 'maintenance', 'error', 'stopped', 'unknow']
const ONLINE = [true, false]

export default function BoxFilter ({ filter, onChangeFilter, owners }) {
  const onChange = (hash) => {
    onChangeFilter({ ...filter, ...hash })
  }
  return (
    <div style={{ display: 'flex', gap: '10px' }}>
      <Input
        style={{ width: '150px' }}
        onChange={(e) => { onChange({ name: e.target.value }) }} placeholder={t('Admin.BoxFilter.name')}
        value={filter.name}
      />
      <Input
        style={{ width: '150px' }}
        onChange={(e) => { onChange({ zip_code: e.target.value }) }} placeholder={t('Admin.BoxFilter.zipCode')}
        value={filter.zip_code}
      />
      <Select
        showSearch
        allowClear={true}
        placeholder={t('Admin.BoxFilter.owner')}
        optionFilterProp="children"
        onChange={(value) => onChange({ owner_id: value || '' })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={owners.map((o) => ({ label: o.name, value: o.id }))}
      />
      <Select
        showSearch
        allowClear={true}
        placeholder={t('Admin.BoxFilter.level')}
        style={{ width: '200px' }}
        optionFilterProp="children"
        onChange={(value) => onChange({ level: value || 'all' })}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={LEVELS.map((level) => ({ label: t(`Admin.Box.level.${level}`), value: level }))}
      />
      <Select
        showSearch
        allowClear={true}
        placeholder={t('Admin.BoxFilter.online')}
        style={{ width: '200px' }}
        optionFilterProp="children"
        onChange={(value) => {
          value = value === undefined ? 'all' : value
          onChange({ online: value })
        }}
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={ONLINE.map((online) => ({ label: t(`Admin.Box.online.${online}`), value: online }))}
      />
    </div>
  )
}
