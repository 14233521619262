import React from 'react'
import { Button } from 'antd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  CalendarOutlined
} from '@ant-design/icons'
import { capitalize } from 'lodash'
import dayjs from 'dayjs'
import { t } from 'i18next'

export const slotTitle = (day) => {
  if (dayjs(day).isSame(dayjs(), 'day')) {
    return t('Public.BoxList.today') + dayjs(day).format(' [à] HH[h]mm')
  } else {
    return dayjs(day).format('dddd D MMMM [à] HH[h]mm')
  }
}

export default function SlotCard ({ date, isNow, box, disabled }) {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const style = styleDefault()

  const navigateTo = (e) => {
    e.stopPropagation()
    navigate({
      pathname: `/box/${box.id}`
    })
  }

  const title = isNow ? t('Public.BoxPage.now') : capitalize(slotTitle(date))
  const description = isNow ? t('Public.BoxPage.description', { date: dayjs(date).format('HH[h]mm') }) : ''
  return (
        <div style={style.box} className="boxPageCard">
            <div className="icon" style={{ textAlign: 'center', display: 'flex' }}>
                <CalendarOutlined width="100px" style={style.icon} />
            </div>
            <div style={style.content} className="content">
                <div className="text" >
                    <b>{title}</b> <br />
                    <p>{description}</p>
                </div>
                {!disabled &&
                    <Button style={{ marginTop: '1em' }} onClick={navigateTo} type="primary">
                        {t('Public.BoxPage.change')}
                    </Button>
                }
            </div>
        </div>
  )
}

const styleDefault = () => ({
  box: {
    boxShadow: '0px 0px 2px 2px #f2f2f2',
    borderRadius: '1em',
    padding: '1em',
    display: 'flex',
    gap: '1em',
    width: '350px',
    margin: '1px'
  },
  icon: {
    display: 'block',
    width: '100px',
    fontSize: '24px',
    alignSelf: 'center'
  },
  content: {
    alignSelf: 'center'
  }
})
