import { Button, Layout, Modal, Spin } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import UsersList from './UsersList'
import UserForm from './UserForm'
import { useForm } from 'antd/es/form/Form'
import { get, post } from '../../Common/api'
import { Color } from '../../Common/Color'

export default function UsersPage () {
  const [users, setUsers] = useState([])
  const [owners, setOwners] = useState()
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = useForm()

  useEffect(() => {
    getUsers()
  }, [])

  const getUsers = async () => {
    setLoading(true)
    await get('admin/users', {}, (res) => {
      setLoading(false)
      setUsers(res.users)
    }, () => {
      setLoading(false)
    })
  }

  const postUser = async (params) => {
    setLoading(true)
    await post('admin/users', params, () => {
      setModalVisible(false)
      setLoading(false)
      getUsers()
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  useEffect(() => {
    getOwners()
  }, [])

  const getOwners = async () => {
    setLoading(true)
    await get('admin/owners', {}, (res) => {
      setLoading(false)
      setOwners(res.owners)
    }, () => {
      setLoading(false)
    })
  }

  const openAddModal = () => {
    form.resetFields()
    setModalVisible(true)
  }

  const onAdd = async (values) => {
    await postUser(values)
  }

  return (
    <Layout>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '20px' }}><b>{users.length}</b> {t('Admin.UsersPage.title')}</div>
          <Button onClick={openAddModal} type="primary" style={{ marginLeft: 'auto' }} shape="circle" icon={<PlusOutlined />} />
        </div>
      </Layout>
      <Spin spinning={loading}>
        <UsersList users={users} />
        {modalVisible && <Modal
          open={modalVisible}
          width="1000px"
          title={t('Admin.UsersPage.addUserTitle')}
          okText={t('actions.validate')}
          onOk={form.submit}
          onCancel={() => setModalVisible(false)}
          confirmLoading={loading}
        >
          <Spin spinning={loading}>
            <UserForm form={form} onFinish={onAdd} owners={owners}/>
          </Spin>
        </Modal>}
      </Spin>
    </Layout>
  )
}
