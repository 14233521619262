import { Button, Checkbox, ConfigProvider, DatePicker, Form, Input, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { useNavigate } from 'react-router'
import { post } from '../../Common/api'
import countryList from 'react-select-country-list'
import dayjs from 'dayjs'
import SlotContext from '../Slot/SlotContext'

export default function SpecialistForm ({ box }) {
  const { data, setData } = useContext(SlotContext)
  const { t } = useTranslation()
  const [form] = useForm()
  const navigate = useNavigate()
  const countryOptions = useMemo(() => countryList().getData(), [])

  const sort = (optionA, optionB) => (
    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
  )

  const onFinish = (values) => {
    const phone = values.phone
    if ((phone.slice(0, 3) === '+06') || (phone.slice(0, 3) === '+07')) {
      values.phone = '+33' + phone.slice(2)
    }
    setData({ ...data, country: values.country, civility: values.civility })
    values = {
      ...values,
      phone: values.phone.slice(1),
      box_id: box.id,
      is_now: false,
      specialist: true
    }
    post('public/slots', values, (json) => {
      if (json.sms_confirmed) {
        navigate(`/slots/${json.id}`)
      } else {
        navigate(`/box/${box.id}/confirm_slot/${json.id}`)
      }
    })
  }

  const civilityOptions = [
    { value: 'MR', label: 'M.' },
    { value: 'MS', label: 'Mme.' }
  ]

  const initialValues = {
    start_timestamp: data?.slotTime?.start,
    end_timestamp: data?.slotTime?.end,
    calendar_id: data?.calendarId,
    reason_id: data?.reasonId,
    date: dayjs(data.slotTime.time)
  }

  return (
    <div className={'slotForm'} style={{ flex: 1 }}>
      <Form
        style={{ flex: 1, fontSize: '1.2em' }}
        labelCol={{ xs: { span: 4 }, sm: { span: 4 }, md: { span: 5 }, lg: { span: 4 } }}
        wrapperCol={{ xs: { span: 20 }, sm: { span: 8 }, md: { span: 10 }, lg: { span: 6 } }}
        id="SlotForm"
        form={form}
        onFinish={onFinish}
        initialValues={initialValues}
        labelWrap
      >
        <Form.Item name='start_timestamp' hidden>
          <Input />
        </Form.Item>
        <Form.Item name='end_timestamp' hidden>
          <Input />
        </Form.Item>
        <Form.Item name='calendar_id' hidden>
          <Input />
        </Form.Item>
        <Form.Item name='reason_id' hidden>
          <Input />
        </Form.Item>
        <Form.Item name='date' hidden>
          <DatePicker />
        </Form.Item>
        <Form.Item name="first_name" label={t('Public.Slot.firstName')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="last_name" label={t('Public.Slot.lastName')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Input />
        </Form.Item>
        <Form.Item name="country" label={t('Public.Slot.country')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Select options={countryOptions} showSearch filterSort={sort} />
        </Form.Item>
        <Form.Item name="civility" label={t('Public.Slot.civility')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Select options={civilityOptions} />
        </Form.Item>
        <Form.Item name="phone" label={t('Public.Slot.phone')}
          extra="+33 6 39 98 80 12"
          rules={[
            { required: true, message: t('rules.required') },
            {
              validator: (_, value) =>
                value ? (isValidPhoneNumber(value) ? Promise.resolve() : Promise.reject(new Error('Numéro incorrect'))) : Promise.reject(new Error(t('rules.required')))
            }]}>
          <PhoneInput className='phone-large-input' international defaultCountry={'FR'} placeholder="+33 6 39 98 80 12" />
        </Form.Item>
        <Form.Item name="email" label={t('Public.Slot.email')}
          rules={[{ required: true, message: t('rules.required') }]}>
          <Input />
        </Form.Item>
        <ConfigProvider theme={{
          token: {
            controlInteractiveSize: 20,
            lineWidth: 2
          }
        }}>
          <Form.Item name="cgu" valuePropName="checked" labelCol={{ span: 10 }} colon={false}
            rules={[{ required: true, message: t('rules.required') },
              { validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Ce champ est obligatoire')) }]}
            label={<>{t('Public.Slot.cgu_1')} <a target="_blank" href="/cgu" style={{ marginLeft: '5px' }}>{t('Public.Slot.cgu_2')}</a></>} >
            <Checkbox />
          </Form.Item>
          <Form.Item name="age_restriction" valuePropName="checked" labelCol={{ span: 10 }} colon={false}
            rules={[{ required: true, message: t('rules.required') },
              { validator: (_, value) => value ? Promise.resolve() : Promise.reject(new Error('Ce champ est obligatoire')) }]}
            label={t('Public.Slot.age_restriction')} >
            <Checkbox />
          </Form.Item>
        </ConfigProvider>
        <Form.Item wrapperCol={{ span: 11 }} style={{ textAlign: 'right', marginTop: '3em' }}>
          <Button type="primary" htmlType="submit">
            {t('Public.Slot.submit')}
          </Button>
        </Form.Item>
      </Form >
    </div>
  )
}
