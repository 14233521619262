import { Button, Form, Input, Select } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import React, { useContext, useEffect, useState } from 'react'
import { get, post } from '../../Common/api'
import GlobalContext from '../GlobalContext'
import _ from 'lodash'

export default function EventFilter ({ setLoading, getEvents }) {
  const [boxes, setBoxes] = useState([])
  const [filters, setFilters] = useState([])
  const [filterOptions, setFilterOptions] = useState([])
  const [currentFilter, setCurrentFilter] = useState()
  const { data } = useContext(GlobalContext)
  const [form] = useForm()

  useEffect(() => {
    getBoxes()
    getFilters()
  }, [])

  useEffect(() => {
    if (currentFilter) {
      form.setFieldsValue(filtersValue(currentFilter))
    }
  }, [currentFilter, form])

  useEffect(() => {
    const options = filters.map((filter) => ({ value: filter.id, label: filter.name }))
    options.push({ value: 'new', label: t('Admin.EventFilter.newFilter') })
    setFilterOptions(options)
  }, [filters])

  const filtersValue = (currentFilter) => {
    const boxId = currentFilter.filters.find((e) => e.name === 'box_id').value
    const period = currentFilter.filters.find((e) => e.name === 'period').value
    const resolved = currentFilter.filters.find((e) => e.name === 'resolved').value
    const number = currentFilter.filters.find((e) => e.name === 'number').value
    return {
      id: currentFilter.id,
      name: currentFilter.name,
      box_id: filterValue(boxId),
      period: filterValue(period),
      resolved: filterValue(resolved),
      number: filterValue(number),
      event_filter: currentFilter.id
    }
  }

  const filterValue = (attribute) => {
    return attribute.length > 0 ? attribute : undefined
  }

  const getFilters = async () => {
    setLoading(true)
    await get('admin/event_filters/user', { user_id: data.user.id }, (res) => {
      setFilters(res.all)
      setCurrentFilter(res.default)
      getEvents(filtersValue(res.default))
    })
    setLoading(false)
  }

  const getBoxes = async () => {
    setLoading(true)
    await get('admin/boxes', {}, (res) => {
      setLoading(false)
      setBoxes(res.boxes.map((box) => ({ value: box.id, label: `${box.name} ${box.zip_code}` })))
    }, () => {
      setLoading(false)
    })
  }

  const onChange = (value) => {
    if (value === 'new') {
      setCurrentFilter({
        id: value,
        name: undefined,
        filters: [
          { name: 'box_id', value: '' }, { name: 'period', value: '' },
          { name: 'resolved', value: '' }, { name: 'number', value: '' }
        ]
      })
    } else {
      setCurrentFilter(filters.find((filter) => filter.id === value))
    }
  }

  const onFinish = (values) => {
    setLoading(true)
    post('admin/event_filters/save', _.merge(values, { user_id: data.user.id }), (res) => {
      setFilters(res)
      setCurrentFilter(res.find((e) => e.name === values.name))
    })
    setLoading(false)
  }

  const searchAction = () => {
    getEvents(form.getFieldsValue())
  }

  const periodOptions = [
    { value: 'day', label: t('Admin.EventFilter.day') },
    { value: 'week', label: t('Admin.EventFilter.week') },
    { value: 'month', label: t('Admin.EventFilter.month') }
  ]

  const resolvedOptions = [
    { value: 'true', label: t('Admin.EventFilter.true') },
    { value: 'false', label: t('Admin.EventFilter.false') }
  ]

  return (
    <div style={{ margin: '1em 0em' }}>
      {
        currentFilter &&
        <Form onFinish={onFinish} form={form} layout='inline'>
          <Form.Item name='id' hidden>
            <Input />
          </Form.Item>
          <Form.Item name='name' rules={[{ required: true }]}>
            <Input placeholder={t('Admin.EventFilter.name')} style={{ width: '15em' }} />
          </Form.Item>
          <Form.Item name='box_id'>
            <Select options={boxes} placeholder={t('Admin.EventFilter.box_id')} optionFilterProp="label" showSearch allowClear
              style={{ width: '15em' }}/>
          </Form.Item>
          <Form.Item name='period'>
            <Select options={periodOptions} placeholder={t('Admin.EventFilter.period')} optionFilterProp="label" showSearch allowClear
              style={{ width: '15em' }} />
          </Form.Item>
          <Form.Item name='resolved'>
            <Select options={resolvedOptions} placeholder={t('Admin.EventFilter.resolved')} optionFilterProp="label" showSearch allowClear
              style={{ width: '15em' }} />
          </Form.Item>
          <Form.Item name='number'>
            <Input placeholder={t('Admin.EventFilter.number')} style={{ width: '15em' }} />
          </Form.Item>
          <div style={{ display: 'flex', marginLeft: '8em' }}>
            <Form.Item name='event_filter'>
              <Select options={filterOptions} onChange={onChange} optionFilterProp="label" showSearch
                style={{ width: '15em' }} />
            </Form.Item>
            <Button type='primary' onClick={form.submit} style={{ margin: '0 1em' }}>{t('actions.save')}</Button>
            <Button type='primary' onClick={searchAction} style={{ margin: '0 1em' }}>{t('actions.search')}</Button>
          </div>
        </Form>
      }
    </div>
  )
}
