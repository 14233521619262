
import { Button, Card, Form, Input } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import {
  LockOutlined,
  UserOutlined,
  LoginOutlined
} from '@ant-design/icons'

import GlobalContext from './GlobalContext'

export default function Login () {
  const { t } = useTranslation()
  const globalContext = useContext(GlobalContext)

  const onFinish = (data) => {
    globalContext.login({ user: data })
  }

  return (
    <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card style={{ width: '30em' }}>
        <Form
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[{ required: true }]}
          >
            <Input
              autoFocus={true}
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder={t('Admin.Login.email')} />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('Admin.Login.password')} />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit" icon={<LoginOutlined />}>
              {t('actions.login')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
