
import { Button, Card, Form, Input } from 'antd'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import {
  LockOutlined
} from '@ant-design/icons'

import GlobalContext from './GlobalContext'
import { useSearchParams } from 'react-router-dom'

export default function UpdatePassword () {
  const { t } = useTranslation()
  const globalContext = useContext(GlobalContext)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    checkToken()
  }, [])

  const checkToken = () => {
    globalContext.checkToken({ reset_password_token: searchParams.get('reset_password_token') })
  }

  const onFinish = (data) => {
    globalContext.updatePassword({ ...data, reset_password_token: searchParams.get('reset_password_token') })
  }

  return (
    <div style={{ height: '90vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card style={{ width: '30em' }}>
        <Form
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('Admin.UpdatePassword.password')} />
          </Form.Item>

          <Form.Item
            name="password_confirmation"
            rules={[{ required: true }]}
          >
            <Input.Password
              prefix={<LockOutlined className="site-form-item-icon" />}
              placeholder={t('Admin.UpdatePassword.passwordConfirmation')} />
          </Form.Item>

          <Form.Item style={{ textAlign: 'center' }}>
            <Button type="primary" htmlType="submit">
              {t('actions.validate')}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  )
}
