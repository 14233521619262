import React from 'react'
import { Card, Col, Row } from 'antd'
import PublicFooter from '../PublicFooter'
import PublicHeader from '../PublicHeader'
import { Color } from '../../Common/Color'

export default function ProPage () {
  return (<div style={{ gap: '1em', height: '100vh', display: 'flex', flexDirection: 'column', backgroundColor: Color.white }}>
    <PublicHeader withSearch={true} />
    <Row style={{ background: 'linear-gradient(white, teal, white)' }} className='hide-md'>
      <Col span={8} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={cssTitle}>
          La Box Médicale&nbsp;: la solution de santé pour votre collectivité !
        </div>
      </Col>
      <Col span={16}>
        <img style={{}} src="/images/box-exterieur.png" title="Image de la Box extérieur" height={400} />
      </Col>
    </Row>
    <Row style={{ background: 'linear-gradient(white, teal, white)' }} className='show-md'>
      <Col span={23} style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        <div style={cssTitle}>
          La Box Médicale&nbsp;:   la solution de santé pour votre collectivité !
        </div>
      </Col>
    </Row>
    <div style={{ fontSize: '18px', padding: '0.5em 60px', maxWidth: '1200px' }}>
      Au sein de votre commune et sur l&apos;ensemble de votre territoire, vous constatez quotidiennement les difficultés majeures auxquelles sont confrontés vos administrés pour accéder à un médecin traitant ou bénéficier d&apos;examens médicaux, qu&apos;ils soient généraux ou spécialisés.
      <br />
      Pour faire face à la diminution de l&apos;offre de soins traditionnelle et permettre au plus grand nombre d&apos;avoir accès à des services de santé de proximité, nous avons créé la Box Médicale.
      La Box Médicale est
      <a target="blank" href="https://viaoccitanie.tv/deserts-medicaux-des-gardois-inventent-la-box-medicale/">
        présentée en avant-première
      </a>
      au Salon des Maires d&apos;Alès le 8 juin 2023.
    </div>
    <div>
      <div style={cssTitle}>
        LE PREMIER CABINET MÉDICAL AUTONOME DE TÉLÉCONSULTATION AUGMENTÉE
      </div>
    </div>
    <div style={{ fontSize: '18px', padding: '0.5em 60px', maxWidth: '1200px' }}>
      La Box Médicale est une cabine spacieuse et connectée, en collaboration avec un réseau majeur de médecins. La borne est équipée pour les examens médicaux généraux (température, auscultation, oxymètre... ) et pour des examens spécialisés dermatologiques, ORL et cardio-vasculaires.

    </div>
    <div>
      <div style={{ fontSize: '30px', fontWeight: 'bold', color: '#ff4438', display: 'inline-block', padding: '0.5em 60px' }}>
        Une solution adaptée aux besoins des patients
      </div>
    </div>
    <div style={{ padding: '0.5em 60px' }}>
      <Row>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/securise.svg" title="Sécurisé" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Sécurisée
          </div>
          La Box Médicale est un espace automatisé et supervisé en temps réel par nos équipes.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/facile-acces.svg" title="Facile d'accès" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Facile d&apos;accès
          </div>
          Un dispositif innovant ouvert 7 jours sur 7.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/automatisee.svg" title="Automatisée" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Automatisée
          </div>
          Un espace où les patients peuvent se rendre en toute autonomie pour une téléconsultation.
        </Card>
        <Card style={{ width: 300, textAlign: 'center', margin: '0 20px 20px 0' }} >
          <div style={{ height: '110px' }}>
            <img src="/images/au-plus-pres.svg" title="Au plus près" width={80} />
          </div>
          <div style={{ fontSize: '20px', fontWeight: 'bold', paddingTop: '20px' }}>
            Proximité maximale
          </div>
          Une solution sans limite d&apos;implantation pour être au plus près de vos administrés.
        </Card>
      </Row>
      <div style={cssExergue}>
        « Une réponse concrète, rapide et proche de chez vous pour faciliter l&apos;accès aux soins pour vos administrés. »
      </div>
    </div>
    <div style={{ padding: '0.5em 60px' }}>
      <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
        Une offre adaptée à tous les territoires&nbsp;:
      </div>
      <div style={{ fontSize: '18px', maxWidth: '1200px', padding: '0.5em 0' }}>
        en zone rurale ou urbaine, que ce soit en version 100 % autonome, hybride ou mobile, la Box s&apos;adapte à toutes les configurations.
        Au sein de votre commune et sur l&apos;ensemble de votre territoire, vous constatez quotidiennement les difficultés majeures auxquelles sont confrontés vos administrés pour accéder à un médecin traitant ou bénéficier d&apos;examens médicaux, qu&apos;ils soient généraux ou spécialisés.

        Pour faire face à la diminution de l&apos;offre de soins traditionnelle et permettre au plus grand nombre d&apos;avoir accès à des services de santé de proximité, nous avons créé la Box Médicale.
      </div>
    </div >
    <div>
      <div style={cssTitle}>
        Nous avons repensé l’offre de téléconsultation
      </div>
    </div>
    <div style={{ padding: '0.5em 60px' }}>
      <Row>
        <Col span={16}>
          <div style={{ fontSize: '30px', fontWeight: 'bold', padding: '0.5em 0', display: 'inline-block' }}>
            La Box Médicale offre une réponse aux limites actuelles de la téléconsultation&nbsp;:
          </div>
          <ul style={{ fontSize: '18px' }} >
            <li style={{ paddingBottom: '0.5em' }}>
              supprimant les contraintes liées aux horaires des lieux d&apos;accueil et du personnel.
            </li>
            <li style={{ paddingBottom: '0.5em' }}>
              créant un espace dédié et protégé.
            </li>
            <li style={{ paddingBottom: '0.5em' }}>
              simplifiant les procédures d&apos;entretien et d&apos;hygiène.
            </li>
          </ul>
        </Col>
      </Row>
    </div>

    <div>
      <div style={cssTitle}>
        Il suffit de 45 jours pour implanter votre Box Médicale
      </div>
    </div>
    <div style={{ padding: '0.5em 60px' }}>
      <div style={{ fontSize: '18px', maxWidth: '1200px', padding: '0.5em 0' }}>
        Il suffit de 45 jours pour implanter votre Box Médicale.<br />
        Notre service, Made in France et déjà connecté à l&apos;ensemble des plateformes de santé,
        est disponible en moins de 45 jours.<br />
        Nos équipes vous accompagnent dans la promotion de ce service de soins et organisent des
        sessions de sensibilisation et de découverte pour les différents acteurs concernés,
        qu&apos;il s&apos;agisse des habitants ou des professionnels de santé.<br />
        Contactez-nous dès maintenant en remplissant le formulaire pour développer une
        offre de soins durable au sein de votre collectivité.
      </div>
    </div >
    <div>
    </div>
    <PublicFooter />
  </div>)
}
const cssTitle = {
  fontSize: '30px',
  fontWeight: 'bold',
  borderWidth: '2px 2px 2px 0px',
  borderStyle: 'solid',
  borderRadius: '0px 30px 30px 0px',
  display: 'inline-block',
  padding: '0.5em 60px',
  margin: '0.5em 0',
  backgroundColor: '#fff'
}
const cssExergue = {
  fontSize: '25px',
  fontWeight: 'bold',
  fontStyle: 'italic',
  display: 'inline-block',
  margin: '0.5em 0',
  backgroundColor: '#fff'
}
