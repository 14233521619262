import { Button, Layout, Modal, Spin } from 'antd'
import { t } from 'i18next'
import React, { useEffect, useState } from 'react'
import { get, post } from '../../Common/api'
import { Color } from '../../Common/Color'
import OwnerFilter from './OwnerFilter'
import OwnerList from './OwnerList'
import OwnerForm from './OwnerForm'
import { useForm } from 'antd/es/form/Form'
import { PlusOutlined } from '@ant-design/icons'

export default function OwnersPage () {
  const [owners, setOwners] = useState([])
  const [filter, setFilter] = useState({ name: '' })
  const [loading, setLoading] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = useForm()

  useEffect(() => {
    getOwners()
  }, [])

  const getOwners = async () => {
    setLoading(true)
    await get('admin/owners', { scope: 'filtered_list' }, (res) => {
      setLoading(false)
      setOwners(res.owners)
    }, () => {
      setLoading(false)
    })
  }

  const postOwner = async (params) => {
    setLoading(true)
    await post('admin/owners', params, () => {
      setModalVisible(false)
      setLoading(false)
      getOwners()
    }, (errors) => {
      setLoading(false)
      form.setFields(
        Object.keys(errors).map((key) => ({
          name: key,
          errors: errors[key]
        }))
      )
    })
  }

  const onChangeFilter = (filter) => {
    setFilter(filter)
  }

  const filteredOwners = owners.filter((owner) => {
    return owner.name.toLowerCase().includes(filter.name.toLowerCase())
  })

  const openAddModal = () => {
    form.resetFields()
    setModalVisible(true)
  }

  const onAdd = async (values) => {
    await postOwner(values)
  }

  return (
    <Layout>
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '20px' }}><b>{owners.length}</b> {t('Admin.OwnersPage.title')}</div>
          <OwnerFilter filter={filter} onChangeFilter={onChangeFilter} />
          <Button onClick={openAddModal} type="primary" style={{ marginLeft: 'auto' }} shape="circle" icon={<PlusOutlined />} />
        </div>
      </Layout>
      <Spin spinning={loading}>
        <OwnerList owners={filteredOwners} />
        {modalVisible && <Modal
          open={modalVisible}
          width="1000px"
          title={t('Admin.OwnersPage.addOwnerTitle')}
          okText={t('actions.validate')}
          onOk={form.submit}
          onCancel={() => setModalVisible(false)}
          confirmLoading={loading}
        >
          <Spin spinning={loading}>
            <OwnerForm form={form} onFinish={onAdd} />
          </Spin>
        </Modal>}
      </Spin>
    </Layout>
  )
}
