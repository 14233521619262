import { Button, Layout, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { get } from '../../Common/api'
import AlertList from './AlertList'

export default function AlertsPage () {
  const [alerts, setAlerts] = useState([])
  const [page, setPages] = useState(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getAlerts()
  }, [page])

  const getAlerts = async () => {
    setLoading(true)
    await get('admin/alerts', { page }, (res) => {
      setLoading(false)
      setAlerts([...alerts, ...res])
    }, () => {
      setLoading(false)
    })
  }

  const loadMore = async () => {
    if (loading) return
    setPages(page + 1)
  }

  return (
    <Layout style={{ marginTop: '1em' }}>
      <Spin spinning={loading}>
        <AlertList alerts={alerts} />
        <br />
        <Button onClick={loadMore}>Plus d&apos;alertes</Button>
      </Spin>
    </Layout>
  )
}
