import { Button, Layout, Modal, Spin } from 'antd'
import React, { useEffect, useState } from 'react'
import { deleteRequest, get, post, put } from '../../Common/api'
import IntervenersList from './IntervenersList'
import { PlusOutlined } from '@ant-design/icons'
import IntervenerForm from './IntervenerForm'
import { useForm } from 'antd/es/form/Form'
import { t } from 'i18next'
import IntervenerFilter from './IntervenerFilter'
import { Color } from '../../Common/Color'

export default function IntervenersPage () {
  const [interveners, setInterveners] = useState([])
  const [boxes, setBoxes] = useState([])
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({ name: '', box_id: '', job: 'all' })
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = useForm()

  useEffect(() => {
    getInterveners()
    getBoxes()
  }, [])

  const formatIntervener = (intervener) => (
    { ...intervener, phones: (intervener.phones.map(phone => ({ ...phone, value: `+${phone.value}` }))) }
  )
  const getInterveners = async () => {
    setLoading(true)
    await get('admin/interveners', {}, (res) => {
      setInterveners([...res.map(i => formatIntervener(i))])
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  const getBoxes = async () => {
    setLoading(true)
    await get('admin/boxes', {}, (res) => {
      setLoading(false)
      setBoxes(res.boxes)
    }, () => {
      setLoading(false)
    })
  }

  const onChangeFilter = (filter) => {
    setFilter(filter)
  }

  const openFormModal = (intervener) => {
    form.resetFields()
    if (intervener) {
      form.setFieldsValue(intervener)
    }
    setModalVisible(true)
  }

  const submitRequest = (values) => {
    setLoading(true)
    if (values.id) {
      onUpdate(values)
    } else {
      onAdd(values)
    }
  }

  const onUpdate = async (values) => {
    await put(`admin/interveners/${values.id}`, values, (res) => {
      const index = interveners.findIndex(i => i.id === res.id)
      interveners[index] = formatIntervener(res)
      setInterveners(interveners)
      setLoading(false)
      setModalVisible(false)
    }, () => {
      setLoading(false)
    })
  }

  const onAdd = async (values) => {
    await post('admin/interveners', values, (res) => {
      setInterveners([...interveners, formatIntervener(res)])
      setLoading(false)
      setModalVisible(false)
    }, () => {
      setLoading(false)
    })
  }

  const deleteIntervener = async (intervener) => {
    await deleteRequest(`admin/interveners/${intervener.id}`, {}, (res) => {
      setInterveners(interveners.filter(intervener => intervener.id !== res.id))
      setLoading(false)
    }, () => {
      setLoading(false)
    })
  }

  const deleteIntervenerModal = async (intervener) => {
    Modal.confirm({
      title: `${t('Admin.IntervenersPage.deletePopupTitle')} "${intervener.last_name} ${intervener.first_name}"`,
      width: '600px',
      okText: t('actions.confirm'),
      onOk: () => deleteIntervener(intervener)
    })
  }

  const filteredInterveners = interveners.filter((intervener) => {
    return intervener.name.toLowerCase().includes(filter.name.toLowerCase()) &&
      (!filter.box_id || intervener.boxes.map(box => box.id).includes(filter.box_id)) &&
      (filter.job === 'all' || intervener.job.includes(filter.job))
  })

  return (
    <Layout style={{ marginTop: '1em' }} >
      <Layout style={{ padding: '1em 0', color: Color.dark_blue, fontSize: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '20px' }}><b>{interveners.length}</b> {t('Admin.BoxesPage.title')}</div>
          <IntervenerFilter boxes={boxes} filter={filter} onChangeFilter={onChangeFilter} />
          <Button onClick={() => openFormModal()} type="primary" style={{ marginLeft: 'auto' }} shape="circle" icon={<PlusOutlined />} />
        </div>
      </Layout>
      <Spin spinning={loading}>
        <IntervenersList interveners={filteredInterveners} openModal={openFormModal} deleteModal={deleteIntervenerModal} />
        {modalVisible && <Modal
          open={modalVisible}
          width="1000px"
          title={t('Admin.IntervenersPage.addIntervenerTitle')}
          okText={t('actions.validate')}
          onOk={form.submit}
          onCancel={() => setModalVisible(false)}
          confirmLoading={loading}
        >
          <Spin spinning={loading}>
            <IntervenerForm form={form} request={submitRequest} />
          </Spin>
        </Modal>}
      </Spin>
    </Layout >
  )
}
