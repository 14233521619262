import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { Button } from 'antd'
import { FormOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

const columns = () => [
  {
    title: t('Admin.OwnerList.table.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    width: 100,
    title: t('Admin.OwnerList.table.nbBoxes'),
    dataIndex: 'nb_boxes',
    key: 'nbBoxes',
    sorter: (a, b) => a.nbBoxes - b.nbBoxes
  },
  {
    width: 50,
    key: 'action',
    render: (_, o) => <Link to={`/admin/owners/${o.id}`}><Button shape="circle" icon={<FormOutlined />} /></Link>
  }
]

export default function OwnerList ({ owners }) {
  return (
    <CustTable
      height={'calc(100vh - 230px)'}
      initColumns={columns()}
      dataSource={owners}
    />
  )
}
