import { Col, DatePicker, Form, Popover, Radio, Row, Select } from 'antd'
import React, { useState } from 'react'
import { t } from 'i18next'
const { RangePicker } = DatePicker

export default function PeriodForm ({ form, interveners, cleanable }) {
  const [periodType, setPeriodType] = useState(form.getFieldValue('period_type'))

  const onDateChange = (value) => {
    if (value[0].isSame(value[1])) {
      form.setFieldsValue({ range: [value[0], value[1].add(1, 'hour')] })
    }
  }

  return (
    <Form
      id="PeriodForm"
      size='small'
      form={form}
      labelAlign='left'
      layout='vertical'
    >
      <Form.Item name="id" hidden />
      <Form.Item name="week_day" hidden />
      { !form.getFieldValue('id') &&
        <Form.Item name="period_type"
          rules={[{ required: true, message: t('rules.required') }]}
        >
          <Radio.Group>
            <Radio value='open' onClick={() => setPeriodType('open')}>{t('Admin.PeriodForm.period_type.open')}</Radio>
            {
              cleanable &&
              <Radio value='cleaning' onClick={() => setPeriodType('cleaning')}>{t('Admin.PeriodForm.period_type.cleaning')}</Radio>
            }
            {
              !cleanable &&
              <Popover content={t('Admin.PeriodForm.noCleaningIntervener')}>
                <Radio disabled>{t('Admin.PeriodForm.period_type.cleaning')}</Radio>
              </Popover>
            }
          </Radio.Group>
        </Form.Item>
      }
      <Row gutter={16}>
        <Col>
          <Form.Item name="range" label={t('Admin.PeriodForm.range')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <RangePicker
              onChange={onDateChange}
              showTime={{ format: 'HH[h]mm' }}
              format={'DD/MM/YY HH[h]mm'} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        { periodType === 'cleaning' &&
          <Form.Item name='intervener_id' label={t('Admin.PeriodForm.intervener')}
          rules={[{ required: true, message: t('rules.required') }]}>
            <Select
              showSearch
              placeholder="Rechercher un intervenant"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={interveners.map((o) => ({ label: o.name, value: o.id }))}
              style={{ width: '23em' }}
              />
          </Form.Item>
        }
      </Row>
    </Form>
  )
}
