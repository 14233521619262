import { List, Tag } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import _ from 'lodash'

export default function LogsPanel ({ logs, date }) {
  return (
    <List
      header={<div style={{ textAlign: 'center' }}>{dayjs(date).format('dddd DD MMMM YYYY')}</div>}
      bordered
      rowKey={(record) => record?.id}
      dataSource={_.orderBy(logs, ['created_at'], ['desc'])}
      renderItem={(log) => (
        <List.Item>
          <Tag color={log.color}>
            <span style={{ fontFamily: 'sans-serif', fontWeight: 'bold' }}>
              {dayjs(log.created_at).format('HH:mm:ss')}
            </span>
          </Tag>
          {log.message}
        </List.Item>
      )}
    />
  )
}
