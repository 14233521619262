import { Button } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Color } from '../../Common/Color'

export default function AvailableNowCard ({ box }) {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const onClick = () => {
    navigate(`/box/${box.id}/slot_create/now`)
  }

  return (
        <div style={{
          boxShadow: '0px 0px 2px 2px #f2f2f2',
          borderRadius: '1em',
          padding: '1em',
          margin: '1px',
          maxWidth: '350px',
          fontSize: '1.2em'
        }}>
            <div style={{ color: Color.green, fontWeight: 'bold' }}>
                {t('Public.BoxPage.availableNow.title')}
            </div>
            <div>
                {t('Public.BoxPage.availableNow.description')}
            </div>

            <Button style={{ marginTop: '0.5em' }} onClick={onClick} type="primary">
                {t('Public.BoxPage.availableNow.button')}
            </Button>

        </div>
  )
}
