import React from 'react'
import { Footer } from 'antd/es/layout/layout'
import { Row, Col } from 'antd'
import { useNavigate } from 'react-router'

export default function PublicFooter () {
  const navigate = useNavigate()

  return (
    <Footer className={'footer'} style={{ backgroundColor: '#1a3544', color: '#fff' }} >
      <Row>
        <Col span={10}>
          <img onClick={() => navigate('/')} style={{ cursor: 'pointer', marginRight: '1em' }} className="logo" src="/images/logo-lbm-footer.svg" alt='Logo' width="200px" />
        </Col>
        <Col span={4}></Col>
        <Col span={10}>
          <a style={{ color: '#FFF' }} href='https://laboxmedicale.fr/mentions-legales/' target='_blank' rel="noreferrer">Mentions légales</a><br />
          <a style={{ color: '#FFF' }} href='https://faq.laboxmedicale.com/'>FAQ</a><br />
        </Col>
      </Row>

      <Row>
        <Col span={10}>
        <a href="mailto:contact@laboxmedicale.com">contact@laboxmedicale.com</a>
        </Col>
        <Col span={10}>
        <Col span={4}></Col>
          <a style={cssCollectivité} href={'https://laboxmedicale.fr'} target='_blank' rel="noreferrer">Vous êtes une collectivité</a>
        </Col>
      </Row>

      <Row>
      </Row>
    </Footer>
  )
}

const cssCollectivité = {
  fontWeight: 'bold',
  borderWidth: '2px',
  borderColor: '#FFF',
  color: '#FFF',
  borderStyle: 'solid',
  borderRadius: '25px',
  padding: '0.5em 60px',
  margin: '0 20px 20px 0',
  cursor: 'pointer'
}
