import { Col, Form, Input, Row, Select } from 'antd'
import React from 'react'
import { t } from 'i18next'

export default function UserForm ({ form, onFinish, owners }) {
  return (
    <Form
      id="userForm"
      form={form}
      labelAlign='left'
      layout='vertical'
      onFinish={onFinish}
    >
      <Row gutter={[16, 16]}>
        <Col>
          <Form.Item name="first_name" label={t('Admin.UserForm.firstName')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="last_name" label={t('Admin.UserForm.lastName')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="email" label={t('Admin.UserForm.email')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
          <Form.Item name="owner_id" label={t('Admin.UserForm.owner')}>
            <Select
              showSearch
              placeholder="Selectionner une collectivité"
              optionFilterProp="children"
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={owners?.map((o) => ({ label: o.name, value: o.id }))}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )
}
