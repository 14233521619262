import { Popover, Tag } from 'antd'
import { PhoneOutlined, TableOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import React from 'react'
import { Link } from 'react-router-dom'
import { SLOT_COLORS } from '../../Common/Color'
import dayjs from 'dayjs'

const SlotContent = (slot) => (
  <>
    <Tag color={t(`Admin.Slot.stateColor.${slot?.state}`)}>{t(`Admin.Slot.state.${slot?.state}`)}</Tag>
    <br />
    <PhoneOutlined /> <i>{slot?.phone}</i> <TableOutlined /> <i>{slot?.digicode}</i>
  </>
)

const title = (slot) => {
  const name = `${slot.first_name} ${slot.last_name}`
  if (slot.slot_type === 'clean') {
    return `[Nettoyage] ${name}`
  } else if (slot.slot_type === 'maintenance') {
    return `[Maintenace] ${name}`
  } else if (slot.slot_type === 'patient') {
    return `${name}`
  }
}

const transformSlot = (slot) => ({
  ...slot,
  start: dayjs(slot.begin_date).format(),
  end: dayjs(slot.end_date).subtract(1, 'second').format(),
  color: SLOT_COLORS[slot.slot_type],
  title: title(slot),
  className: !slot.sms_confirmed && 'slot-stripped'
})

export default function OneLineSlot ({ slot, onCalendar = true }) {
  const expendedSlot = onCalendar ? slot : transformSlot(slot)
  const style = onCalendar ? { color: 'inherit', textDecoration: 'inherit' } : { backgroundColor: expendedSlot.color, color: 'white', padding: '0 5px', borderRadius: '5px' }
  return (
    <Popover content={() => SlotContent(expendedSlot)} title={expendedSlot.title}>
      <Link style={style} to={`/admin/slots/${expendedSlot.id}`}>
        <div style={{ whiteSpace: 'nowrap' }} >
          {expendedSlot.title}
        </div>
      </Link>
    </Popover>
  )
}
