import React from 'react'
import Input from 'react-phone-number-input/input'

export default function UserCard ({ value }) {
  const inputStyle = { border: 'none', padding: '0', width: '100%', height: 'inherit', color: 'initial', lineHeight: 'initial' }

  return (
    <Input international value={'+' + value} disabled="disabled" style={inputStyle} onChange={() => {}} />
  )
}
