
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { get } from '../../Common/api'

import { useSearchParams } from 'react-router-dom'
import SpecialistForm from './Form'
import SlotLayout from '../Slot/SlotLayout'

export default function CreateSpecialistSlotPage () {
  const { id } = useParams()
  const { t } = useTranslation()
  const [box, setBox] = useState(null)
  const [params, _setParams] = useSearchParams()

  useEffect(() => {
    get(`public/boxes/${id}`, {}, (json) => {
      setBox(json)
    })
  }, [id])

  return (
    <SlotLayout date={params.get('date')} box={box} title={t('Public.CreateSlotPage.title')}>
      <SpecialistForm box={box} />
    </SlotLayout>
  )
}
