import React from 'react'
import CustTable from '../../Common/CustTable'
import { t } from 'i18next'
import { FormOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button, Popover } from 'antd'
import { Color } from '../../Common/Color'
import { formatPhoneNumber } from 'react-phone-number-input'

const columns = (openModal, deleteModal) => [
  {
    width: 100,
    title: t('Admin.IntervenersList.table.boxes'),
    dataIndex: 'boxes',
    key: 'boxes',
    render: (_, o) => o?.boxes?.map((box) => box.name).join(' - ') || 'Aucune box'
  },
  {
    width: 50,
    title: t('Admin.IntervenersList.table.job.label'),
    dataIndex: 'job',
    key: 'job',
    render: (_, o) => t(`Admin.IntervenersList.table.job.${o.job}`),
    sorter: (a, b) => t(`Admin.IntervenersList.table.job.${a.job}`).localeCompare(t(`Admin.IntervenersList.table.job.${b.job}`))
  },
  {
    width: 75,
    title: t('Admin.IntervenersList.table.name'),
    dataIndex: 'name',
    key: 'name',
    sorter: (a, b) => a.name.localeCompare(b.name)
  },
  {
    width: 150,
    title: t('Admin.IntervenersList.table.phones'),
    dataIndex: 'phones',
    key: 'phones',
    render: (_, o) => displayContactMethod(o?.phones.map(e => ({ ...e, key: e.id })))
  },
  {
    width: 150,
    title: t('Admin.IntervenersList.table.emails'),
    dataIndex: 'emails',
    key: 'emails',
    render: (_, o) => displayContactMethod(o?.emails.map(e => ({ ...e, key: e.id })))
  },
  {
    width: 30,
    key: 'action',
    render: (_, o) => <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      <Button shape="circle" icon={<FormOutlined />} onClick={() => openModal(o)} />
      <Button shape="circle" icon={<DeleteOutlined />} onClick={() => deleteModal(o)} style={{ color: Color.primary }} />
    </div>
  }
]

const displayContactMethod = (fields) => {
  const value = (field) => (
    field.field_type === 'phone' ? formatPhoneNumber(field.value) : field.value
  )

  return (
    <div style={{ display: 'flex' }}>
      {fields?.map((field) => (
        <div style={{ marginRight: '1em' }} key={`contact-${field.description}`}>
          {field.description &&
            <Popover content={field.description}>
              {value(field)}
            </Popover>
          }
          {!field.description && value(field)}
        </div>
      ))}
    </div>
  )
}

export default function IntervenersList ({ interveners, openModal, deleteModal }) {
  return (
    <CustTable
      height={'calc(100vh - 230px)'}
      initColumns={columns(openModal, deleteModal)}
      dataSource={interveners.map((intervener) => ({ ...intervener, key: intervener.id }))}
    />
  )
}
