import { t } from 'i18next'
import React, { useContext } from 'react'
import SlotLayout from '../Slot/SlotLayout'
import Specialist from '../Specialist/Specialist'
import SpecialistContext from '../Specialist/SpecialistContext'

export default function BoxSpecialistSlotList () {
  const { box } = useContext(SpecialistContext)

  return (
    <SlotLayout box={box} title={t('Public.BoxPage.title')}>
      <Specialist box={box} />
    </SlotLayout>
  )
}
