import React, { useContext, useState } from 'react'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Color } from '../../Common/Color'
import { Calendar, Spin, Typography } from 'antd'
import dayjs from 'dayjs'
import SpecialistContext from './SpecialistContext'
import { t } from 'i18next'

export default function SpecialistCalendar () {
  const [calendarLoading, setCalendarLoading] = useState(false)
  const { monthAvailabilities, date, getMonthAvailabilities, specialists, getDayAvailabilities, reason, setDay, box, day } = useContext(SpecialistContext)

  const dateCellRender = (value) => {
    if (monthAvailabilities && date && value.$M === date.month()) {
      return <div className='custom' style={{ color: monthAvailabilities[value.$D - 1].color, '--background-color': monthAvailabilities[value.$D - 1].color, height: '49px', paddingTop: '10px', fontSize: '16px' }}>
          {value.$D}
        </div>
    } else {
      return <div style={{ height: '49px', paddingTop: '10px', fontSize: '16px' }}>{value.$D}</div>
    }
  }

  const headerRender = ({ value, onChange }) => {
    const title = dayjs(value.$d).format('MMMM YYYY')
    const onClick = (newMonth) => {
      setCalendarLoading(true)
      let newDate
      if (newMonth > 11) {
        newDate = value.clone().add(1, 'month').startOf('month')
      } else if (newMonth < 0) {
        newDate = value.clone().subtract(1, 'month').startOf('month')
      } else {
        newDate = value.clone().month(newMonth).startOf('month')
      }
      newDate = newDate < dayjs() && newMonth === dayjs().month() ? dayjs() : newDate
      if (newDate >= dayjs() && newDate <= dayjs().add(60, 'day')) {
        getMonthAvailabilities(newDate, specialists)
        onChange(newDate)
      }
      setCalendarLoading(false)
    }

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: Color.green, borderRadius: '16px 16px 0 0', color: Color.white }}>
        <div className='hoverable' onClick={() => { onClick(value.month() - 1) }} style={{ fontSize: '2em', margin: '0.5em 0.7em 0 0.7em', cursor: 'pointer', '--hover-color': Color.red }}><LeftOutlined /></div>
        <Typography.Title level={4} style={{ color: Color.white }}>{title}</Typography.Title>
        <div className='hoverable' onClick={() => { onClick(value.month() + 1) }} style={{ fontSize: '2em', margin: '0.5em 0.7em 0 0.7em', cursor: 'pointer', '--hover-color': Color.red }}><RightOutlined /></div>
      </div>
    )
  }

  const onSelect = (selectedDay) => {
    if (selectedDay.month() !== day.month()) {
      getMonthAvailabilities(selectedDay.startOf('month'), specialists)
    }
    setDay(selectedDay)
    getDayAvailabilities(selectedDay, reason.id, specialists, box)
  }

  return (
    <Spin spinning={calendarLoading}>
      <Typography.Title level={2} style={{ marginBottom: '1em', textAlign: 'center' }}>{t('Public.Specialist.dateSelection')}</Typography.Title>
      <Calendar fullscreen={false} dateFullCellRender={dateCellRender} headerRender={headerRender} onChange={onSelect} style={{ width: '30em', boxShadow: '1px 1px 10px #4f5d75' }}
      validRange={[dayjs().subtract(1, 'day'), dayjs().add(60, 'day')]} />
    </Spin>
  )
}
