import { Col, DatePicker, Form, Input, Radio, Row } from 'antd'
import React from 'react'
import { t } from 'i18next'
import TextArea from 'antd/es/input/TextArea'
import PhoneInput from 'react-phone-number-input'
const { RangePicker } = DatePicker

export default function SlotForm ({ form, onFinish, edit, rangeDisable, disabled = false, specialist = false }) {
  const onDateChange = (value) => {
    if (value[0].isSame(value[1])) {
      form.setFieldsValue({ range: [value[0], value[1].add(1, 'hour')] })
    }
    form.setFieldsValue({ appointment_date: value[0] })
  }

  return (
    <Form
      id="SlotFormAdmin"
      size='small'
      form={form}
      labelAlign='left'
      layout='vertical'
      onFinish={onFinish}
      disabled={disabled}
    >

      <Form.Item name="slot_type"
        rules={[{ required: true, message: t('rules.required') }]}
      >
        <Radio.Group disabled={edit}>
          <Radio value='patient'>{t('Admin.SlotForm.slot_type.patient')}</Radio>
          <Radio value='maintenance'>{t('Admin.SlotForm.slot_type.maintenance')}</Radio>
          <Radio value='clean'>{t('Admin.SlotForm.slot_type.cleaning')}</Radio>
        </Radio.Group>
      </Form.Item>
      {form.getFieldValue('box_name') &&
        <Row gutter={16}>
          <Col>
            <Form.Item name="box_name" label={t('Admin.SlotForm.box')}>
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
      }
      <Row gutter={16}>
        <Col>
          <Form.Item name="range" label={t('Admin.SlotForm.range')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <RangePicker
              onChange={onDateChange}
              showTime={{ format: 'HH[h]mm' }}
              format={'DD/MM/YY HH[h]mm'}
              disabled={rangeDisable} />
          </Form.Item>
        </Col>
        {edit && <Col>
          <Form.Item name="digicode" label={t('Admin.SlotForm.digicode')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>}
      </Row>
      <Row gutter={16}>
        <Col span={10}>
          <Form.Item name="appointment_date" label={t('Admin.SlotForm.appointment_date')} >
            <DatePicker
              format={'DD/MM/YY HH[h]mm'}
              disabled />
          </Form.Item>
        </Col>
        {edit && specialist &&
          <Col span={10}>
            <Form.Item name="specialist_name" label={t('Admin.SlotForm.specialistName')} >
              <Input disabled />
            </Form.Item>
          </Col>
        }
      </Row>

      <Row gutter={16}>
        <Col>
          <Form.Item name="first_name" label={t('Admin.SlotForm.first_name')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="last_name" label={t('Admin.SlotForm.last_name')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <Input />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="company" label={t('Admin.SlotForm.company')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Form.Item name="phone" label={t('Admin.SlotForm.phone')}
            rules={[{ required: true, message: t('rules.required') }]}>
            <PhoneInput className='phone-input' disabled={disabled}
              international defaultCountry={'FR'}
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name="email" label={t('Admin.SlotForm.email')}>
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item name="comment" label={t('Admin.SlotForm.comment')}>
        <TextArea rows={5} />
      </Form.Item>
      <Form.Item name="is_now">
        <Input type='hidden' />
      </Form.Item>
    </Form>
  )
}
