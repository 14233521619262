import { Button, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { t } from 'i18next'
import _, { capitalize } from 'lodash'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { Color } from '../../Common/Color'
import AvailableNowCard from './AvailableNowCard'

export const slotTitle = (day) => {
  if (dayjs(day).isSame(dayjs(), 'day')) {
    return t('Public.BoxList.today')
  } else {
    return dayjs(day).format('dddd D MMMM')
  }
}

export default function BoxSlotList ({ box }) {
  const { t } = useTranslation()
  const [limitDay, setLimitDay] = useState(4)
  const navigate = useNavigate()

  const availableSlot = (slot) => (
    <div
      className='hoverable'
      key={dayjs(slot.time).format('dd/mm/yyyy HH:mm')}
      onClick={() => onClick(slot.time)}
      style={{
        textAlign: 'center',
        width: '115px',
        display: 'inline-block',
        fontSize: '16px',
        border: '1px solid black',
        borderRadius: '2em',
        padding: '0.4em 1em',
        '--hover-color': Color.red,
        '--hover-border-color': Color.red,
        cursor: 'pointer'
      }}>
      {dayjs(slot.time).format('HH:mm')}
    </div>
  )

  const unavailableSlot = (slot) => (
    <Tooltip title={t('Public.BoxPage.unavailable')}>
      <div
        className='hoverable'
        key={dayjs(slot.time).format('dd/mm/yyyy HH:mm')}
        style={{
          textAlign: 'center',
          width: '115px',
          display: 'inline-block',
          fontSize: '16px',
          border: '1px solid black',
          borderRadius: '2em',
          padding: '0.4em 1em',
          backgroundColor: 'lightgrey'
        }}>
        {dayjs(slot.time).format('HH:mm')}
      </div>
    </Tooltip>
  )

  const days = _.groupBy(box?.next_slots, (slot) => {
    return dayjs(slot.time).format('YYYY-MM-DD')
  })

  const onClick = (slot) =>
    navigate(`/box/${box.id}/slot_create?date=${dayjs(slot).toISOString()}`)

  const hourList = (slots) => (
    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: '1em' }}>
        {
          slots.map((slot) => (
            slot.available ? availableSlot(slot) : unavailableSlot(slot)
          ))
        }
    </div>
  )

  const more = () => {
    setLimitDay(limitDay + 4)
  }

  const daysList = _.take(Object.keys(days), limitDay).map((day) => (
    <div key={slotTitle(day)}>
        <div style={{ fontSize: '20px', marginTop: '1em', marginBottom: '0.5em' }}>
            {capitalize(slotTitle(day))}
        </div>
        {hourList(days[day])}
    </div>
  ))

  return (
    <div style={{ overflow: 'auto' }}>
        {
            box && box['available_now?'] &&
            <AvailableNowCard box={box} />
        }
        {daysList}
        <Button style={{ marginTop: '2em' }} onClick={more} type="primary">{t('Public.BoxPage.more')}</Button>
    </div>
  )
}
