import React from 'react'

export default function BoxSchedules () {
  const style = {
    boxShadow: '0px 0px 2px 2px #f2f2f2',
    borderRadius: '1em',
    padding: '1em 0',
    gap: '1em',
    width: '350px',
    margin: '1px'
  }
  return (
    <div style={style}>
      <div style={{ display: 'flex', justifyContent: 'space-around', minWidth: '25em' }}>
        <div style={{ fontSize: '1.2em' }}>
          <div>
            <p style={{ margin: '0 0 0.5em 0' }} ><b>Horaires</b></p>
            <p style={{ margin: '0 0' }}>Lundi :</p>
            <p style={{ margin: '0 0' }}>Mardi :</p>
            <p style={{ margin: '0 0' }}>Mercredi :</p>
            <p style={{ margin: '0 0' }}>Jeudi :</p>
            <p style={{ margin: '0 0' }}>Vendredi :</p>
            <p style={{ margin: '0 0' }}>Samedi :</p>
            <p style={{ margin: '0 0' }}>Dimanche :</p>
          </div>
        </div>
        <div style={{ fontSize: '1.2em' }}>
          <div>
            <p style={{ margin: '0 0 0.5em 0' }}><b>Ouvertures</b></p>
            <p style={{ margin: '0 0' }}>8h-20h</p>
            <p style={{ margin: '0 0' }}>8h-20h</p>
            <p style={{ margin: '0 0' }}>8h-20h</p>
            <p style={{ margin: '0 0' }}>8h-20h</p>
            <p style={{ margin: '0 0' }}>8h-20h</p>
            <p style={{ margin: '0 0' }}>9h-19h</p>
            <p style={{ margin: '0 0' }}>10h-16h</p>
          </div>
        </div>
        <div style={{ fontSize: '1.2em' }}>
          <div>
            <p style={{ margin: '0 0 0.5em 0' }}><b>Consultations</b></p>
            <p style={{ margin: '0 0' }}>8h30-19h30</p>
            <p style={{ margin: '0 0' }}>8h30-19h30</p>
            <p style={{ margin: '0 0' }}>8h30-19h30</p>
            <p style={{ margin: '0 0' }}>8h30-19h30</p>
            <p style={{ margin: '0 0' }}>8h30-19h30</p>
            <p style={{ margin: '0 0' }}>9h-18h30</p>
            <p style={{ margin: '0 0' }}>10h-15h30</p>
          </div>
        </div>
      </div>
    </div>
  )
}
